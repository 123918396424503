import { getTimeZones } from '@vvo/tzdb';

export const getClientTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimeZoneList = () => {
  let lastPrefix = '';

  const sortedTz = getTimeZones().sort((a, b) => (b.name < a.name ? 1 : -1));
  let timeZoneList = sortedTz
    .reduce((list, tz) => {
      const offset = tz.currentTimeOffsetInMinutes / 60;
      const timeZoneId = tz.name;

      if (timeZoneId.indexOf('/') === -1) {
        return list;
      }

      const groupIds = tz.group.reduce((ids, city) => {
        if (
          city !== timeZoneId &&
          city.indexOf('/') > -1 &&
          list.findIndex((i) => i.value === city) === -1
        ) {
          ids.push(city);
        }
        return ids;
      }, []);
      const ids = [ timeZoneId, ...groupIds ];

      ids.forEach((id) => {
        const suffix = id.split('/')[1];
        const formattedSuffix = suffix.replace(/_/g, ' ');

        list.push({
          value: id,
          text: `${formattedSuffix} (GMT ${offset})`,
        });
      });

      return list;
    }, [])
    .sort((a, b) => (b.value < a.value ? 1 : -1));

  timeZoneList = timeZoneList.reduce((list, tz) => {
    const prefix = tz.value.split('/')[0];
    if (prefix !== lastPrefix) {
      list.push({
        header: prefix,
      });
      lastPrefix = prefix;
    }

    list.push(tz);

    return list;
  }, []);

  timeZoneList.push(
    { header: 'UTC', value: 'UTC', text: 'UTC (GMT +0)' },
    { value: 'UTC', text: 'UTC (GMT +0)' },
  );

  return timeZoneList;
};
