var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitMethod($event)
        }
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          label: "Proposal Owner Email",
          autofocus: "",
          disabled: !!_vm.user,
          rules: [_vm.checkEmail],
          "error-messages": _vm.errors.email,
          loading: _vm.emailLoading
        },
        on: {
          blur: function() {
            return _vm.checkForAvailability()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                !!_vm.user
                  ? _c(
                      "a",
                      {
                        staticClass: "allow-pointer",
                        attrs: {
                          target: "_blank",
                          rel: "noopener noreferrer",
                          href: _vm.getProfileLink()
                        }
                      },
                      [_vm._v(" Edit User Profile ")]
                    )
                  : _vm._e(),
                _vm.showSignInLink
                  ? _c("a", { attrs: { href: _vm.getSignInLink() } }, [
                      _vm._v(" Already Registered? Sign In ")
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.editedOwnerEmail,
          callback: function($$v) {
            _vm.editedOwnerEmail = $$v
          },
          expression: "editedOwnerEmail"
        }
      }),
      _c("v-text-field", {
        attrs: {
          rules: [
            function(v) {
              return !!v || "Company Name is required"
            }
          ],
          "error-messages": _vm.errors.name,
          label: "Company Name"
        },
        model: {
          value: _vm.editedCompany.name,
          callback: function($$v) {
            _vm.$set(_vm.editedCompany, "name", $$v)
          },
          expression: "editedCompany.name"
        }
      }),
      _c("vuetify-google-autocomplete", {
        attrs: {
          id: "address",
          label: "Address",
          value: _vm.editedCompany.address,
          "error-messages": _vm.errors.address,
          hint: "Visible on proposal"
        },
        on: { placechanged: _vm.getAddressData }
      }),
      _c("v-text-field", {
        attrs: { label: "Address Line 2" },
        model: {
          value: _vm.editedCompany.address2,
          callback: function($$v) {
            _vm.$set(_vm.editedCompany, "address2", $$v)
          },
          expression: "editedCompany.address2"
        }
      }),
      _c("v-text-field", {
        attrs: {
          label: "Company Website",
          placeholder: "Add website here...",
          rules: [_vm.urlRule],
          "error-messages": _vm.errors.website
        },
        model: {
          value: _vm.editedCompany.website,
          callback: function($$v) {
            _vm.$set(_vm.editedCompany, "website", $$v)
          },
          expression: "editedCompany.website"
        }
      }),
      _c("v-autocomplete", {
        attrs: {
          label: "Currency",
          items: _vm.supportedCurrencies,
          disabled: ""
        },
        model: {
          value: _vm.editedCompany.currency,
          callback: function($$v) {
            _vm.$set(_vm.editedCompany, "currency", $$v)
          },
          expression: "editedCompany.currency"
        }
      }),
      _c("v-autocomplete", {
        attrs: { label: "Time Zone", items: _vm.timeZones },
        model: {
          value: _vm.editedCompany.time_zone_id,
          callback: function($$v) {
            _vm.$set(_vm.editedCompany, "time_zone_id", $$v)
          },
          expression: "editedCompany.time_zone_id"
        }
      }),
      _c("FileUploader", {
        attrs: { files: _vm.logoPhotos },
        on: { fileChanged: _vm.imageFileChanged }
      }),
      _vm.showLogoPreview
        ? _c(
            "div",
            { staticClass: "company-logo-wrapper mt-4" },
            [
              _c("ImageComponent", {
                attrs: {
                  image: _vm.logoObject,
                  "show-title": false,
                  size: "thumbnail"
                },
                on: { imageDelete: _vm.handleImageDeleteClick }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "placeholder-image mt-4" },
            [
              _c("v-icon", { attrs: { "x-large": "" } }, [
                _vm._v(" fa-user-circle ")
              ])
            ],
            1
          ),
      _c("hidden-submit-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }