<template>
  <v-form @submit.prevent="submitMethod" lazy-validation>
    <v-text-field
      label="Proposal Owner Email"
      autofocus
      :disabled="!!user"
      v-model="editedOwnerEmail"
      :rules="[checkEmail]"
      @blur="() => checkForAvailability()"
      :error-messages="errors.email"
      :loading="emailLoading"
    >
      <template v-slot:append>
        <a
          class="allow-pointer"
          v-if="!!user"
          target="_blank"
          rel="noopener noreferrer"
          :href="getProfileLink()"
        >
          Edit User Profile
        </a>
        <a
          v-if="showSignInLink"
          :href="getSignInLink()"
        >
          Already Registered? Sign In
        </a>
      </template>
    </v-text-field>

    <v-text-field
      :rules="[v => !!v || 'Company Name is required']"
      :error-messages="errors.name"
      v-model="editedCompany.name"
      label="Company Name"
    />

    <vuetify-google-autocomplete
        id="address"
        label="Address"
        @placechanged="getAddressData"
        :value="editedCompany.address"
        :error-messages="errors.address"
        hint="Visible on proposal"
    />
    <v-text-field
        v-model="editedCompany.address2"
        label="Address Line 2"
    />

    <v-text-field
      v-model="editedCompany.website"
      label="Company Website"
      placeholder="Add website here..."
      :rules="[urlRule]"
      :error-messages="errors.website"
    />
    <v-autocomplete
      v-model="editedCompany.currency"
      label="Currency"
      :items="supportedCurrencies"
      disabled
    />
    <v-autocomplete
      v-model="editedCompany.time_zone_id"
      label="Time Zone"
      :items="timeZones"
    />
    <FileUploader
      :files="logoPhotos"
      @fileChanged="imageFileChanged" 
    />
    <div class="company-logo-wrapper mt-4" v-if="showLogoPreview">
      <ImageComponent
        :image="logoObject"
        :show-title="false"
        size="thumbnail"
        @imageDelete="handleImageDeleteClick"
      />
    </div>
    <div v-else class="placeholder-image mt-4">
      <v-icon x-large>
        fa-user-circle
      </v-icon>
    </div>
    <hidden-submit-button />
  </v-form>
</template>

<script>
import FileUploader from '@/components/common/FileUploader.vue';
import HiddenSubmitButton from '@/components/common/HiddenSubmitButton.vue';
import ImageComponent from '@/domains/proposals/components/image';
import CHECK_EMAIL from '@/graphql/Companies/EmailCheck.gql';
import COMPANY_EDIT from '@/graphql/Companies/CompanyEdit.gql';
import { currencyList } from '@/utils/formatting/pricing';
import { getClientTimeZone, getTimeZoneList } from '@/domains/proposals/utils/timezone';
import { emailRegex, urlRegex } from '@/utils/misc';

export default {
  name: 'CompanyEditor',

  components: {
    FileUploader,
    HiddenSubmitButton,
    ImageComponent,
  },

  props: {
    company: Object,
    owner_email: String,
    updateCompanySettings: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editedOwnerEmail: this.owner_email,
      supportedCurrencies: currencyList,
      timeZones: getTimeZoneList(),
      defaultCompany: {
        id: '0',
        owner_company_id: null,
        name: '',
        address: '',
        address2: '',
        decimal_structure: 'comma_dot',
        phone: '',
        website: '',
        logo: null,
        logo_on_proposal: true,
        time_zone_id: getClientTimeZone(),
        date_format: 'm/d/Y',
        time_format: '12h',
        address_format: '%address%',
        weekdays: false,
        currency: 'USD',
        color: '#5c8ab5',
        url_segment: '',
        tax_rates_count: null,
        pdf_sheet_size: 'A4',
        __typename: 'Company',
      },
      editedCompany: {},
      emailLoading: false,
      errors: {
        email: [],
        name: [],
        physicalAddress: [],
        website: [],
      },
      skipEmailQuery: true,
      logoPhotos: [],
      companyLogoSoftDeleted: false,
    };
  },

  apollo: {
    emailAvailable: {
      query: CHECK_EMAIL,
      variables() {
        return {
          email: this.editedOwnerEmail,
        };
      },
      skip() {
        return this.skipEmailQuery;
      },
    },
  },

  computed: {
    companyLogoUrl() {
      return this.$store.state.auth.company.logo[0] || '';
    },

    logoObject() {
      return {
        alt: 'Company Logo',
        height: 100,
        width: 100,
        title: 'Company Logo',
        url: this.uploadedLogo || this.companyLogoUrl,
      };
    },

    saveBtnEnabled() {
      const ec = this.editedCompany;
      const oc = this.company;
      const ec_address = `${ec.address}${ec.address_place_id}${ec.address_lat}${ec.address_lng}${ec.address_street_number}${ec.address_route}${ec.address_locality}${ec.address_postal_code}${ec.address_area_1}${ec.address_area_2}${ec.address_country}${ec.address_subpremise}`;
      const oc_address = `${oc.address}${oc.address_place_id}${oc.address_lat}${oc.address_lng}${oc.address_street_number}${oc.address_route}${oc.address_locality}${oc.address_postal_code}${oc.address_area_1}${oc.address_area_2}${oc.address_country}${oc.address_subpremise}`;

      const emailChanged = this.owner_email !== this.editedOwnerEmail;
      const nameChanged = this.company.name !== this.editedCompany.name;
      const addressChanged = oc_address !== ec_address;
      const address2Changed = this.company.address2 !== this.editedCompany.address2;
      const currencyChanged = this.company.currency !== this.editedCompany.currency;
      const timeZoneChanged = this.company.time_zone_id !== this.editedCompany.time_zone_id;
      const websiteChanged = this.company.website !== this.editedCompany.website;
      const logoChanged = !!this.logoPhotos[0] || this.companyLogoUrl;

      return emailChanged || nameChanged || addressChanged || address2Changed
          || currencyChanged|| timeZoneChanged || websiteChanged || logoChanged;
    },

    showLogoPreview() {
      return this.uploadedLogo || (this.companyLogoUrl && !this.companyLogoSoftDeleted);
    },

    showSignInLink() {
      // We check for false specifically as emailAvailable starts
      // as undefined and the response will be either true or false
      return !this.user && (this.emailAvailable === false);
    },

    uploadedLogo() {
      return this.logoPhotos[0]?.url;
    },

    user() {
      return this.$store.state.auth.user;
    },

    urlRule() {
      return v => ! v || v.length === 0 || urlRegex.test(v || '') || 'Invalid web address';
    },
  },

  methods: {
    checkEmail() {
      return emailRegex.test( String(this.editedOwnerEmail).toLowerCase() ) || 'Invalid email';
    },

    async checkForAvailability() {
      this.emailLoading = true;

      this.$apollo.queries.emailAvailable.skip = false;
      await this.$apollo.queries.emailAvailable.refetch();
      this.$apollo.queries.emailAvailable.skip = true;

      this.errors.email = this.emailAvailable ? [] : [ 'Email not available' ];
      this.emailLoading = false;
    },

    getAddressData(addressComponents, { formatted_address }) {
      this.editedCompany.address = formatted_address;
      this.editedCompany.address_place_id = addressComponents.place_id;
      this.editedCompany.address_lat = addressComponents.latitude;
      this.editedCompany.address_lng = addressComponents.longitude;
      this.editedCompany.address_street_number = addressComponents.street_number;
      this.editedCompany.address_route = addressComponents.route;
      this.editedCompany.address_locality = addressComponents.locality;
      this.editedCompany.address_postal_code = addressComponents.postal_code;
      this.editedCompany.address_area_1 = addressComponents.administrative_area_level_1;
      this.editedCompany.address_area_2 = addressComponents.administrative_area_level_2;
      this.editedCompany.address_country = addressComponents.country;
      this.editedCompany.address_subpremise = addressComponents.subpremise ? addressComponents.subpremise : '';
    },

    getProfileLink() {
      return `${process.env.VUE_APP_CU_APP_URL}profile`;
    },

    getSignInLink() {
      return `${process.env.VUE_APP_CU_APP_URL}logon?redirect_to=${encodeURIComponent(window.location.href)}`;
    },

    handleImageDeleteClick() {
      if ( this.uploadedLogo ) {
        this.logoPhotos = [];
        return;
      }
      
      if ( !this.uploadedLogo && '' !== this.companyLogoUrl ) {
        this.companyLogoSoftDeleted = true;
        return;
      }
    },

    imageFileChanged(imageObject) {
      if (!imageObject) {
        return;
      }

      this.logoPhotos = [ imageObject ];
    },

    updateCompany() {
      let logoUrl = this.uploadedLogo || this.companyLogoUrl;

      if ( !this.uploadedLogo && '' !== this.companyLogoUrl && this.companyLogoSoftDeleted ) {
        logoUrl = '';
      }

      const logo = {
        url: logoUrl,
        company_id: this.editedCompany.id, 
        user_id: this.$store.state.auth.user.id,
        hasChanged: !!this.logoPhotos[0] || ('' !== this.companyLogoUrl && this.companyLogoSoftDeleted),
      };

      const { id, name, address, address2, currency, time_zone_id, website } = this.editedCompany;
      const input = { id, name, address, address2, currency, time_zone_id, website, logo };

      this.$apollo.mutate({
        mutation: COMPANY_EDIT,
        variables: { input },
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.editedCompany.logo = [ logoUrl ];
        this.$store.commit('auth/setCompany', this.editedCompany);
      });
    },

    save(close) {
      if (this.updateCompanySettings) {
        this.updateCompany();
      } else {
        this.$store.commit('updateCompany', this.editedCompany);
        this.$store.commit('updateOwnerEmail', this.editedOwnerEmail);
        this.$store.commit('setCurrentChanged', true);
      }
      close();
    },

    submitMethod() {
      if (this.saveBtnEnabled) {
        this.$emit('enterPress');
      }
    },
  },

  mounted() {
    this.editedCompany = null === this.company.id ? { ...this.defaultCompany } : { ...this.company };
  },
};
</script>

<style lang="scss">
.allow-pointer {
  pointer-events: all;
}

.company-logo-wrapper .image > img {
  width: 150px;
  height: 150px;
}

.placeholder-image {
  align-items: center;
  border: 1px solid rgba(30, 30, 30, 0.5);
  display: flex;
  height: 150px;
  justify-content: center;
  width: 150px;
}
</style>
